import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useRef } from "react";
import instance from "../../../utils/axios";
import { API } from "../../../utils/apiendpoints";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";


const Step4 = ({ setStep, children }) => {
  const numberOfInputs = 4;
  const [otpValues, setOtpValues] = useState(Array(numberOfInputs).fill(""));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const handleChange = (e, index) => {
    const value = e.target.value;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value.length === 1 && /^[0-9]$/.test(value)) {
      if (index < numberOfInputs - 1) {
        const nextInput = e.target.nextSibling;
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };
  const formRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const index = e.target.dataset.index;
      const newOtpValues = [...otpValues];
      if (index > 0) {
        newOtpValues[index - 1] = ""; // Clear the previous input
        const prevInput = document.querySelector(
          `input[data-index="${index - 1}"]`
        );
        if (prevInput) {
          prevInput.focus();
        }
      }
      newOtpValues[index] = ""; // Clear the current input
      setOtpValues(newOtpValues);
    }
    else if (e.key === 'Enter') {
      e.preventDefault();
      // handleSubmit(e);
      handleVerification()
    }
  };

  const handleInput = (e, index) => {
    e.target.value = e.target.value.match(/^([0-9]{0,1})/)[0];
    const enteredValue = e.target.value;

    if (enteredValue === "0" && index === 0) {
      e.target.value = "";
      return;
    }
    if (index === 3) {
      e.target.value = e.target.value.match(/^([0-9]{0,1})/)[0];
    }
    handleChange(e, index);
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    const otpSepratedArray = pastedText.split("");
    setOtpValues(otpSepratedArray);
    otpSepratedArray.forEach((item, index) => {
      const otpFieldElement = document.getElementById(`otpInput${index}`);
      if (otpFieldElement) {
        otpFieldElement.value = item;
      }
    });
    const lastInput = document.getElementById("otpInput3");
    if (lastInput) {
      lastInput.focus();
    }
  };

  const handleVerification = async () => {
    let value = parseInt(otpValues.join(""));
    const userEmail = queryParams.get('email');
    // delete params.socialToken;
    const params = {
      email: userEmail,
      otp: `${value}`
    }

    await instance
      .post(`${API.verifyEmail}`, params)
      .then((res) => {
        if (!res.error) {
          // if (socialToken) {
          //   handleSocialRegister();
          // } else {
          //   handleRegisterUser();
          // }
          setStep((prev) => prev + 1);
        }
      })
      .catch((error) => {
      });
  };

  const sendCode = () => {
    const userEmail = queryParams.get('email');
    const params = {
      email: userEmail
    }
    instance.post(API.emailVerification, params)
      .then((res) => {
        if (!res?.error) {
          toast.success(`${res?.data?.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
  }

  return (
    <>
      <div className="verify-otp text-center">
        <div className="inner-block">
          <p className="fw-semibold fs-5">Enter the verification code</p>
          <p className="notify-para text-center">
            We sent a verification code to your email, please enter it below.{" "}
          </p>
          <div className="otp-input">
            {[...Array(4)].map((value, index) => (
              <input
                key={index}
                placeholder="-"
                id={`otpInput${index}`}
                maxLength={1}
                value={otpValues[index]}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={handleKeyDown}
                onInput={(e) => handleInput(e, index)}
                onPaste={(e) => handlePaste(e)}
                data-index={index}
              />
            ))}
          </div>
          <div className="resend-otp">
            <p className="mb-0 cursor-pointer" onClick={() => sendCode()}>
              <span>Didn’t get the code?</span> <span className="fw-bold">Resend code.</span>
            </p>
          </div>
        </div>
        <Button
          className="custom-btn mt-24 mb-24"
          variant="dark"
          // onClick={() => setStep((prev) => prev + 1)}
          onClick={handleVerification}
        >
          Verify Code
        </Button>
        {children}

      </div>
    </>
  );
};

export default Step4;
