import React from "react";

const Input = ({
  name,
  disabled = false,
  placeholder,
  register,
  errors,
}) => {
  return (
    <div className="common-input  my-2 position-relative">
      <input
        type="text"
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        {...register}
        className={`${errors[name]?.type ? "error-border" : "normal-border"} ${disabled ? "disabled-fields" : ""}`}
      />
      {errors[name]?.type && (
        <p className={`error-text `}>{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default Input;
