import { useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

const ProtectedRoutes = ({ isAuthenticated, isPublic }) => {
    const [auth, setAuth] = useState(null);

    useEffect(() => {
        const fetchAuthentication = async () => {
            try {
                const result = await isAuthenticated();
                setAuth(result);
            } catch (error) {
                console.error('Error during authentication:', error);
                setAuth(false); // Set authentication to false in case of an error
            }
        };

        fetchAuthentication();
    }, [isAuthenticated]);

    if (auth === null) {
        // Still waiting for authentication result
        return null;
    }

    return (
        (isPublic && !auth) || (!isPublic && auth) ? (
            <Outlet />
        ) : (
            <Navigate to={isPublic ? '/dashboard' : '/login'} />
        )
    );
};

export default ProtectedRoutes;
