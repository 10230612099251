import { useEffect, useState } from "react";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const initialstep = searchParams.get("step");
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  //redirection only for step 3
  useEffect(() => {
    if (initialstep === '3') {
      setStep(3);
    }
  }, [])
  if (step === 1) {
    return (
      <Step1 setStep={setStep}>
      </Step1>
    );
  } else if (step === 2) {
    return (
      <Step2 setStep={setStep}>
      </Step2>
    );
  } else if (step === 3) {
    return (
      <Step3 setStep={setStep}>
      </Step3>
    );
  } else if (step === 4) {
    return (
      <Step4 setStep={setStep}>
      </Step4>
    );
  } else if (step === 5) {
    return (
      navigate('/thankyou')
    );
  }
};

export default SignUp;
