import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages";
import LoginPage from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import ThankyouPage from "./pages/thankyou";
import SignupPage from "./pages/signup";
import DashboardPage from "./pages/dashboard";
import ProtectedRoutes from "./components/shared/ProtectedRoutes";
import { jwtDecode } from "jwt-decode";
import { supabase } from "./utils/supabaseClient";

function App() {
  const isAuthenticated = async () => {
    try {
      const { data, error } = await supabase.auth.getSession();
      if (data.session !== null) return true;
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        return false;
      }
      let decodedToken = jwtDecode(accessToken);
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      // If there's an error in decoding, return false
      return false;
    }
  };
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} isPublic />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/thankyou" element={<ThankyouPage />} />
        </Route>
        <Route element={<ProtectedRoutes isAuthenticated={isAuthenticated} />}>
          <Route element={<DashboardPage />} path="/dashboard" />
        </Route>
      </Routes>
    </Router >
  );
}

export default App;
