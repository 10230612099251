export const API = {
  registerUser: "/create",
  registerSocialUser: "/oAuth/register",
  loginUser: "/authenticate",
  forgotPassword: "/forgot_password",
  resetPassword: "/reset_password",
  emailVerification: "/send-verify-mail",
  verifyEmail: "/verify-mail",
  checkEmail: "/check-email",
  authOAuthCheck: "/oAuth/check",
  getCountries: "/get_countries",
};
