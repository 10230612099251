import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../utils/apiendpoints";
import instance from "../utils/axios";

const initialState = {
  loader: false,
  userInfo: {},
};

export const registerUser = createAsyncThunk(
  "register/user",
  async (params, { rejectWithValue }) => {
    const response = await instance
      .post(API.registerUser, params)
      .then((res) => {
        return res?.data?.data?.user;
      })
      .catch((error) => {
        return rejectWithValue(error.message);
      });
    return response;
  }
);

export const registerSocialUser = createAsyncThunk(
  "register/user",
  async (params, { rejectWithValue }) => {
    const response = await instance
      .post(API.registerSocialUser, params)
      .then((res) => {
        return res?.data?.data;
      })
      .catch((error) => {
        return rejectWithValue(error.message);
      });
    return response;
  }
);

const registerSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, param) => {
      const { payload } = param;
      state.userInfo = { ...state.userInfo, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loader = false;
        state.userInfo = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loader = false;
      });
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerSocialUser.pending, (state, action) => {
        state.loader = true;
      })
      .addCase(registerSocialUser.fulfilled, (state, action) => {
        state.loader = false;
        state.userInfo = action.payload;
      })
      .addCase(registerSocialUser.rejected, (state, action) => {
        state.loader = false;
      });
  },
});

export const { setUserDetails } = registerSlice.actions;

export default registerSlice.reducer;
